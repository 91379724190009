
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap.scss";
@import "bower_components/fontawesome/scss/font-awesome.scss";
@import "node_modules/spinkit/scss/spinkit.scss";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";
@import "node_modules/sweetalert/dev/sweetalert.scss";
@import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox";

@import "./animate.scss";
@import "./cart-system";
@import "./icons";

[v-cloak] {
  display: none;
}

.text-left-imp {
  text-align: left!important;
}

.book-btn {
  margin-bottom: 20px;
}

.gallery-image {
  cursor: zoom-in;
}

.footer-logos {
  padding: 10px 0;
  margin: 30px 0;
  text-align: center;
  border-radius: 5px;
}

.footer-logos img {
  margin: 5px 0;
  width: 75%;
}

.contact-table {

}
.contact-phone {

}
.contact-phone-mobile {

}
.contact-fax {

}
.contact-email {

}
.contact-address {

}

.table
{
  margin: 20px 0;
}